/* NotFound.css (CSS for 404 error page) */

.not-found-container {
  text-align: center;
  margin-top: 100px;
}

.not-found-title {
  font-size: 48px;
  color: #ff6347; /* You can choose your preferred color */
}

.not-found-text {
  font-size: 18px;
  color: #333;
  margin-top: 10px;
}

.not-found-link {
  text-decoration: none;
  background-color: #007acc; /* Button background color */
  color: #fff; /* Button text color */
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.not-found-link:hover {
  background-color: #005f9e; /* Hover background color */
}
