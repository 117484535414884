@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&family=Playfair+Display&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Euphoria+Script&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: #666666ee;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.1px;
  /* background-position: center center; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 11px 25px;
  background-color: #78804c96;
  color: #ffffff;
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  /* font-family: "Playfair Display", serif; */
  font-size: 12px;
  margin-top: 25px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: rgba(124, 115, 96, 0.741);
}

.MAINbox {
  position: relative;
  min-height: calc(100vh - 70px);
  display: "flex";
  flex-direction: "column";
  padding-bottom: 150px;
}

@media (max-width: 768px) {
  body {
    background-image: url("./images/background-phone.webp");
  }
}
