.WeddingGiftPageBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 100px;
}

.WeddingGiftBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* margin-left: 10%; */
}

.WeddingGiftTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Euphoria Script", cursive;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  font-size: 36px;
  padding: 10px;
  color: #4a4a4a;
  margin: 0;
}
.WeddingGiftTitle svg {
  margin-right: 15px;
  margin-bottom: 7px;
}

.WeddingGiftParagraph {
  color: #4a4a4a;
  font-size: 22px;
  width: 80%;
  margin: 0;
  margin-top: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
}

.WeddingGiftParagraphBank {
  color: #4a4a4a;
  font-size: 22px;
  width: 80%;
  margin: 0;
  margin-top: 10px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.WeddingGiftImg {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 50px;
}

.linkToMap {
  text-decoration: none;
  /* color: inherit; */
  font-weight: 700;
  color: rgb(176, 110, 30);
}

@media (max-width: 768px) {
  .WeddingGiftTitle {
    font-size: 36px;
  }
  .WeddingGiftParagraph {
    font-size: 16px;
    width: 100%;
  }

  .WeddingGiftSubTitle {
    color: #4a4a4a;
    font-size: 18px;
    width: 100%;
    margin: 0;
    margin-top: 5px;
    font-family: "Josefin Sans", sans-serif;
    font-family: "Playfair Display", serif;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .WeddingGiftParagraphBank {
    color: #4a4a4a;
    font-size: 18px;
    width: 95%;
  }
}
