.navbar.scrolled {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.flags {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  padding: 5px;
}
.imageContainer {
  height: 30px;
  width: 40px;
  cursor: pointer;
}
.flagBtn {
  height: 30px;
  width: 40px;
  border-radius: 2rem;
}

.menu-button {
  align-items: center;
  height: 60px;
  position: fixed;
  top: 20px;
  /* left: 0; */
  right: 0;
  transition: all 1.5s;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 25px;
  margin-right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.bar {
  width: 100%;
  height: 4px;
  background: rgb(142, 128, 49);
  transition: 0.4s ease-in-out;
}

/* Nine animations for the bars */
.menu-button.open .bar:nth-child(1) {
  animation: rotate1 0.4s ease-in-out forwards;
  background: red;
}

.menu-button.open .bar:nth-child(2) {
  animation: fadeOut 0.4s ease-in-out forwards;
  background: red;
}

.menu-button.open .bar:nth-child(3) {
  animation: rotate2 0.4s ease-in-out forwards;
  background: red;
}

@keyframes rotate1 {
  100% {
    transform: rotate(45deg) translateY(10px);
  }
}

@keyframes rotate2 {
  100% {
    transform: rotate(-45deg) translateY(-10px);
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}

.visible {
  top: 20px;
  transition: all 0.2s;
}

.nonVisible {
  top: -30px;
  /* transition: all 0.3s; */
}

.menu-container {
  z-index: 99;
  position: fixed;
  top: 0;
  right: -400px;
  height: 100vh;
  width: 400px;
  background: white;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-around; */
  transition: right 0.5s ease-in-out;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.menu-container svg {
  margin-right: 15px;
}

.menu-container.open {
  right: 0;
}
ul,
ol {
  padding-left: 0;
}
.menu-list {
  list-style: none;
  width: 100%;
}

.hamburgerNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  /* font-weight: bold; */
  color: rgb(142, 128, 49);
  padding: 15px;
  margin: 10px;
  cursor: pointer;
  transition: 0.3s;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;

  /* transition: 0.3s; */
}

.hamburgerNav:hover {
  /* color: rgba(153, 36, 36, 0.702); */
  font-weight: bold;
}
.TheWeddingArrow {
  margin-left: 30px;
}
.subNavMenu {
  margin-left: 10px;
}

.weddingMessage {
  font-family: "Euphoria Script", cursive;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  color: #4a4a4a;
  background-color: #fff8e7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 500px;
}

.NamesInTitle {
  font-size: 2em;
  margin-top: 15px;
  margin-bottom: 0.5em;
  color: #6d6875;
}

.DateInTitle {
  font-size: 1.5em;
  color: #6d6875;
}

@media (max-width: 768px) {
  .menu-container {
    right: -300px;
    width: 300px;
  }
}
