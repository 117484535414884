.AlbumPageBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 100px;
}

.AlbumBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* margin-left: 10%; */
}

.AlbumTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Euphoria Script", cursive;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  font-size: 36px;
  padding: 10px;
  color: #4a4a4a;
  margin: 0;
}
.AlbumTitle svg {
  margin-right: 15px;
  margin-bottom: 7px;
}

.AlbumParagraph {
  color: #4a4a4a;
  font-size: 22px;
  width: 80%;
  margin: 0;
  margin-top: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
}

.AlbumSubTitle {
  color: #4a4a4a;
  font-size: 22px;
  width: 80%;
  margin: 0;
  margin-top: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
}

.AlbumImg {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 50px;
}

.linkToMap {
  text-decoration: none;
  /* color: inherit; */
  font-weight: 700;
  color: rgb(176, 110, 30);
}

.photosBtn {
  margin-top: 50px;
  text-decoration: none;
  color: white;
  background-color: #4a4a4a;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 3px;
  border-radius: 1rem;
  transition: all 0.5s;
}

.photosBtn:hover {
  background-color: #4a4a4ad6;
}
@media (max-width: 768px) {
  .AlbumTitle {
    font-size: 36px;
  }
  .AlbumParagraph {
    font-size: 16px;
    width: 100%;
  }

  .AlbumSubTitle {
    color: #4a4a4a;
    font-size: 18px;
    width: 100%;
    margin: 0;
    margin-top: 5px;
    font-family: "Josefin Sans", sans-serif;
    font-family: "Playfair Display", serif;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
