/* LoadingDots.css */
.loading-dots {
  display: inline-block;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #fff; /* Change the color as desired */
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
  animation: bounce 1.6s infinite; /* Animation properties */
}

.dot:nth-child(2) {
  animation-delay: 0.4s; /* Delay the second dot by 0.5 seconds */
}

.dot:nth-child(3) {
  animation-delay: 0.8s; /* Delay the third dot by 1 second */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Adjust the distance for the bounce effect */
  }
}
