.home {
  display: flex;
  flex-direction: column;
}
.homeMessage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(227, 225, 213, 255);
}
.welcomeImage {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 1000px;
  left: calc(250 - 100%);

  z-index: 1;
}
.homeMessageHeart {
  padding: 8px;
  background-color: rgba(76, 9, 9, 0.282);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homeMessageHeartIcon {
  color: red;
  font-size: 20px;
}

.homeWeddingParagraph {
  position: absolute;
  top: 50%;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  width: 80%;
  z-index: 2;
}

@keyframes throwAndFall {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(-6px);
  }
  80%,
  100% {
    transform: translateY(0);
  }
}

.downArrow {
  position: absolute;
  bottom: 40px;
  animation: throwAndFall 1.3s ease-in-out infinite;
  font-size: 25px;
  cursor: pointer;
  z-index: 2;
}

.ImageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 50px;
}

.ImageBox img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.detailedInformation {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 16px;
  text-align: left;
  width: 80%;
  padding-left: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.CeremonyAndCelebration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  border: 1px #4f514b79 solid;
  border-radius: 1.5rem;
  /* padding: 20px; */
}

.CeremonyAndCelebration img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-top: 20px;
  margin-bottom: 15px;
}

.CeremonyAndCelebration h5 {
  margin: 0;
  margin-top: 10px;
  font-size: 18px;
}
.CeremonyAndCelebration p {
  margin: 0;
  margin-top: 10px;
  font-size: 16px;
}

.BoxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-align: center;
  width: 80%;
  /* padding: 20px; */
  margin-top: 40px;
}

.BusesAlbum {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 42%;
  height: 200px;
  border: 1px #4f514b79 solid;
  border-radius: 1.5rem;
  padding: 20px;
  margin: 5px;
}

.BusesAlbum img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.BusesAlbum h5 {
  margin: 0;
  margin-top: 7px;
  font-size: 18px;
}
.BusesAlbum p {
  margin: 0;
  margin-top: 7px;
  font-size: 16px;
}

.confirmAttendance {
  /* margin-top: 100px; */
  height: 240px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(164, 179, 130, 0.25);
}
.ConfirmBtn {
  padding: 14px 50px;
}
.iconBox {
  padding: 8px;
  background-color: #735c256a;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}
.iconBox svg {
  font-size: 17px;
  color: white;
}

.boxTitle {
  font-family: "Yellowtail", cursive;
  font-size: 32px;
  color: #666666f6;
  font-weight: 400;
  margin: 0;
}

.WeddingGift {
  margin-top: 30px;
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.SaveDate {
  margin-top: 20px;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(164, 179, 130, 0.25);
}

.SaveDate p {
  color: #4a4a4a;
}

.ExtraSpace {
  display: none;
}

@media (max-width: 768px) {
  .homeWeddingParagraph {
    text-align: center;
    /* font-size: 1.2em; */
  }
  .ExtraSpace {
    display: block;
  }
  .confirmAttendance {
    height: 300px;
  }

  .detailedInformation {
    font-size: 15px;
    text-align: left;
    width: 90%;
    padding-left: 00px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .BoxContainer {
    flex-direction: column;
    width: 75%;
  }
  .CeremonyAndCelebration {
    width: 75%;
    height: 250px;
    padding: 20px;
  }
  .BusesAlbum {
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
    height: 250px;
  }
  .ImageBox h4 {
    margin-left: 10px;
    padding-right: 0;
  }

  .boxTitle {
    width: 95%;
  }
}
