.CeremonyAndCelebrationPageBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 100px;
}

.CeremonyAndCelebrationBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-left: 10%; */
}
.fixedWidth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.CeremonyAndCelebrationTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Euphoria Script", cursive;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  font-size: 36px;
  padding: 10px;
  color: #4a4a4a;
  margin: 0;
  width: 100%;
}
.CeremonyAndCelebrationTitle svg {
  margin-right: 7px;
  margin-bottom: 7px;
}

.CeremonyAndCelebrationParagraph {
  color: #4a4a4a;
  font-size: 22px;
  width: 80%;
  margin: 0;
  margin-top: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
}

.CeremonyAndCelebrationImg {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 50px;
}

.linkToMap {
  text-decoration: none;
  font-weight: 700;
  color: rgb(176, 110, 30);
}

@media (max-width: 768px) {
  .CeremonyAndCelebrationTitle {
    font-size: 30px;
  }
  .CeremonyAndCelebrationParagraph {
    font-size: 16px;
    width: 100%;
  }
}
