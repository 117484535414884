.ConfirmYourAttendancePageBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 100px;
}

.ConfirmYourAttendanceBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* margin-left: 10%; */
}

.ConfirmYourAttendanceTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Euphoria Script", cursive;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  font-size: 36px;
  padding: 10px;
  color: #4a4a4a;
  margin: 0;
}
.ConfirmYourAttendanceTitle svg {
  margin-right: 15px;
  margin-bottom: 7px;
}

.ConfirmYourAttendanceParagraph {
  color: #4a4a4a;
  font-size: 22px;
  width: 80%;
  margin: 0;
  margin-top: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
}

.ConfirmYourAttendanceImg {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 50px;
}

.linkToMap {
  text-decoration: none;
  /* color: inherit; */
  font-weight: 700;
  color: rgb(176, 110, 30);
}
.attendanceForm {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}
.attendanceFormIn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.attendingFormLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
}

.AttendingFormMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  flex: 1;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 500;
}
.AttendingFormMessage textarea {
  width: 100%;
  min-height: 100px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.66);
}
.InputsFormatting {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 500;
}
.InputsFormatting input {
  width: 90%;
  min-height: 25px;
  background-color: rgba(255, 255, 255, 0.66);
}
.InputsFormatting textarea {
  width: 100%;
  min-height: 100px;
  background-color: rgba(255, 255, 255, 0.66);
}

.choice-box {
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px;
  border: 2px solid #4a4a4a4d;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.choice-box.selected {
  background-color: rgba(124, 115, 96, 0.741);
  color: white;
}

.attendance-choice {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
}

.error-message {
  color: #fc2b2e;
  padding: 5px 10px;
  font-weight: 700;
  font-style: italic;
  /* margin-top: 5px; */
  border-radius: 4px;
  font-size: 0.9em;
  display: inline-block;
}
.HiredBus {
  display: flex;
  flex-direction: row;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

.error-message {
  animation: shake 0.5s ease-in-out;
}

@media (max-width: 1000px) {
  .attendanceForm {
    width: 100%;
  }

  .attendanceFormIn {
    flex-direction: column;
  }

  .attendingFormLeftSide {
    width: 100%;
  }
  .InputsFormatting input {
    width: calc(100% - 6px);
  }

  .InputsFormatting textarea {
    width: calc(100% - 6px);
  }
  .AttendingFormMessage textarea {
    width: calc(100% - 6px);
  }
  .InputsFormatting {
    width: 100%;
  }
  .AttendingFormMessage {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ConfirmYourAttendanceTitle {
    font-size: 36px;
  }
  .ConfirmYourAttendanceParagraph {
    font-size: 16px;
    width: 100%;
  }
  .ConfirmAttend {
    width: 100%;
  }

  .HiredBus {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
  }

  .attendance-choice {
    flex-direction: column;
    text-align: left;
    width: 100%;
  }
  .choice-box {
    width: calc(100% - 10px);
    margin: 5px;
    padding: 5px;
  }
}
