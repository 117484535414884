/* Footer container */
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Sacramento", cursive;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4a4a4a;
  background-color: #fff8e7;
  padding: 50px;
  height: 70px;
}

.NamesInTitleOnFooter {
  font-size: 2em;
  margin-top: 5px;
  margin-bottom: 0.5em;
  color: #6d6875;
}

.DateInTitleOnFooter {
  font-size: 1.5em;
  color: #6d6875;
}
